* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

.swal2-container {
  z-index: 10000 !important;
}

.Typography-FormTitle {
  font-size: 1rem !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

.iconNotificationSize {
  font-size: 1.8rem !important;
}

.truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.children-inline {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  flex: 0 0 auto;
}